import React from "react"

import useNewsletterForm from "@src/hooks/useNewsletterForm"

import TwitterIcon from "@src/icons/footer/twitter.svg"

import * as styles from "./Newsletter.module.scss"

const Buttons = ({ location }) => {
  const { form, setForm, submit, loading } = useNewsletterForm(
    location,
    "30DaysOfCanonic"
  )
  const activeClassName = loading === "SUCCESS" ? styles.active : ""
  return (
    <div className={styles.newsletter}>
      <div className={styles.newsletterColumn}>
        <div className={styles.newsletterJoinUs}>
          <input
            placeholder="Write your email id"
            className={styles.newsletterInput}
            type="text"
            htmlFor="email"
            value={form.email}
            onChange={setForm("email")}
          />
          <button onClick={submit} className={styles.newsletterButton}>
            Join Us
          </button>
          <div className={`${styles.newsletterSuccess} ${activeClassName}`}>
            ✅ You've subscribed to the newsletter successfully!
          </div>
        </div>
        <button className={styles.newsletterTwitterButton}>
          <a
            href="https://twitter.com/CanonicHQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="twitter" src={TwitterIcon} /> Follow on Twitter
          </a>
        </button>
      </div>
    </div>
  )
}

export default Buttons
