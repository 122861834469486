// extracted by mini-css-extract-plugin
export var active = "ContentOfTheDay-module--active--Jc-Jj";
export var card = "ContentOfTheDay-module--card--3TFJb";
export var cardBody = "ContentOfTheDay-module--cardBody--3hN6g";
export var cardBodyButtonGroup = "ContentOfTheDay-module--cardBodyButtonGroup--1GNc-";
export var cardBodyLink = "ContentOfTheDay-module--cardBodyLink--1C2vn";
export var cardContainer = "ContentOfTheDay-module--cardContainer--uIU3F";
export var cardContainerColumn = "ContentOfTheDay-module--cardContainerColumn--coBTH";
export var cardDayText = "ContentOfTheDay-module--cardDayText--3k61i";
export var cardHeader = "ContentOfTheDay-module--cardHeader--RLMjg";
export var cardHeaderLogo = "ContentOfTheDay-module--cardHeaderLogo--8aBBj";
export var cardRow = "ContentOfTheDay-module--cardRow--2i6ts";
export var cards = "ContentOfTheDay-module--cards--p6-tt";
export var cardsCol = "ContentOfTheDay-module--cardsCol--1VhKp";
export var hidden = "ContentOfTheDay-module--hidden--iYoWu";
export var last = "ContentOfTheDay-module--last--1ZjQB";
export var secondLast = "ContentOfTheDay-module--secondLast--3fru2";
export var showIn = "ContentOfTheDay-module--show-in--1nHKE";
export var templates = "ContentOfTheDay-module--templates--2gCeT";