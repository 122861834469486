import React, { useCallback, useEffect, useState } from "react"

import * as styles from "./Reaction.module.scss"

const Reaction = ({ id, day }) => {
  const [upvote, setUpvote] = useState(false)
  const [downvote, setDownvote] = useState(false)

  useEffect(() => {
    setUpvote(localStorage.getItem(`like${day}`) === id ? true : false)
    setDownvote(localStorage.getItem(`dislike${day}`) === id ? true : false)
  }, [])

  const handleLikeClick = useCallback(() => {
    window.analytics &&
      window.analytics.track("30 Days reaction triggered", {
        value: `Day ${day}`,
        event: "upvote",
      })
    localStorage.setItem(`like${day}`, id)
    setUpvote(true)
  }, [upvote])
  const handleDilikeClick = useCallback(() => {
    window.analytics &&
      window.analytics.track("30 Days reaction triggered", {
        value: `Day ${day}`,
        event: "downvote",
      })
    localStorage.setItem(`dislike${day}`, id)

    setDownvote(true)
  }, [downvote])

  const votedClassName = upvote || downvote ? styles.footerVoted : ""
  return (
    <div className={styles.footer}>
      <button
        className={`${votedClassName} ${styles.footerReaction}`}
        onClick={handleLikeClick}
        disabled={upvote || downvote}
      >
        👍
      </button>
      <button
        className={`${styles.footerReaction} ${votedClassName}`}
        onClick={handleDilikeClick}
        disabled={downvote || upvote}
      >
        👎
      </button>
    </div>
  )
}

export default Reaction
