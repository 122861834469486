import React from "react"

import Layout from "@src/components/Layout"
import CommunityCallout from "@src/components/CommunityCallout"
import Section from "@src/components/Section"
import Text from "@src/components/Text"
import ContentOfTheDay from "./components/ContentOfTheDay/index"
import Newsletter from "./components/Newsletter"

import ThirtyDaysOfCanoic from "@src/icons/v2/30DaysOfCanonic.svg"
import Triangle from "@src/icons/v2/30-days-triangle.svg"
import OG from "@src/icons/og/OG_Thirty_Days.jpeg"
import * as styles from "./30DaysOfCanonic.module.scss"

const LandingPage30DaysOfCanonic = ({ location }) => {
  return (
    <Layout
      title="30 Days Of Canonic"
      description="Participate in a month long event to learn how to build various backends on Canonic’s low code platform. Enjoy a detailed walkthrough of all the features with us. Special rewards for people who complete this journey."
      url="https://canonic.dev/30-days-of-canonic"
      location={location}
      ogImage={OG}
    >
      <Section
        screenshot={ThirtyDaysOfCanoic}
        blueGradient
        className={styles.section}
      >
        <div className={styles.sectionHeader}>
          <Text.H2 white> #30DaysOfCanonic</Text.H2>

          <Text.P white>
            Participate in a month long event to learn how to build various
            backends on Canonic’s low code platform. Enjoy a detailed
            walkthrough of all the features with us.
            <br /> Special rewards for people who complete this journey.
          </Text.P>
          <Newsletter />
        </div>
      </Section>
      <div id="top" />
      <Section noMargin white className={styles.sectionTimeline}>
        <Text.Heading>THE TIMELINE</Text.Heading>
        <Text.H2 lightBlueGradient>
          Here are the things we <br />
          will build together
        </Text.H2>
        <figure>
          <img
            src={Triangle}
            alt="30 days of Canonic"
            className={styles.Triangle}
          />
        </figure>
        {<ContentOfTheDay />}
      </Section>
      <CommunityCallout />
    </Layout>
  )
}

export default LandingPage30DaysOfCanonic
