import React from "react"
import activateOnScreen from "@src/hoc/activateOnScreen"
import Text from "@src/components/Text"
import Button from "@src/components/Button"
import * as styles from "./Section.module.scss"

const Section = activateOnScreen(
  ({
    innerRef,
    heading,
    superscript,
    title,
    description,
    icon: Icon,
    screenshot,
    screenshotToSide,
    shape,
    url,
    TitleComponent = Text.H2,
    children,
    flipped,
    blueGradient,
    badge,
    className,
    buttons = [],
    buttonsWrapperClassName = "",
    ...classNameProps
  }) => {
    const flippedClassName = flipped ? styles.flipped : ""
    const blueGradientClassName = blueGradient ? styles.blueGradient : ""
    const screenshotToSideClassName = screenshotToSide
      ? styles.screenshotToSide
      : "" // Makes screenshot stick to right side
    const classNames = Object.keys({ ...classNameProps })
      .map(key => (classNameProps[key] ? styles[key] || "" : ""))
      .join(" ")
    const screenshotToRender = screenshot ? (
      <img alt={title} src={screenshot} />
    ) : null

    return (
      <>
        <section
          ref={innerRef}
          className={`${styles.section} ${flippedClassName} ${classNames} ${className} ${blueGradientClassName}`}
        >
          <div className={styles.sectionWrapper}>
            {classNameProps.withoutImage || !Icon ? null : Icon ? (
              <div className={styles.sectionColumn}>
                <Icon className={styles.sectionImage} />
              </div>
            ) : null}
            {classNameProps.withoutImage || !screenshot ? null : screenshot ? (
              <div
                className={`${styles.sectionColumnscreenshot} ${screenshotToSideClassName} `}
              >
                <figure className={styles.sectionImage}>
                  {screenshotToRender}
                </figure>
              </div>
            ) : null}
            <div className={styles.sectionColumn}>
              {heading && <Text.Heading>{heading}</Text.Heading>}
              {superscript ? (
                <Text.H6
                  className={styles.superscript}
                  whiteTitle
                  noMargin={!title}
                >
                  {superscript}
                </Text.H6>
              ) : null}
              <TitleComponent
                white={
                  classNameProps.blue || classNameProps.black || blueGradient
                }
                noMargin={!description}
              >
                {title}
              </TitleComponent>
              {description ? (
                <Text.Subtitle
                  white={classNameProps.blue || classNameProps.black}
                >
                  {description}
                </Text.Subtitle>
              ) : null}
              {url && (
                <a href={url.link} target="_blank" className={styles.link}>
                  {url.name}
                </a>
              )}
              {buttons.length || badge ? (
                <div
                  className={`${styles.sectionButtons} ${buttonsWrapperClassName}`}
                >
                  {buttons.map(
                    ({
                      title,
                      onClick,
                      isPrimary,
                      href = "",
                      className = "",
                    }) => (
                      <a
                        key={title}
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          key={title}
                          onClick={onClick}
                          isPrimary={isPrimary}
                        >
                          {title}
                        </Button>
                      </a>
                    )
                  )}
                  {badge}
                </div>
              ) : null}
              {children}
            </div>
          </div>
        </section>
      </>
    )
  }
)

export default Section
