import React from "react"
import Text from "@src/components/Text"
import Button from "@src/components/Button"
import Reaction from "../Reaction"

import * as styles from "./ContentOfTheDay.module.scss"
import { TIMELINE_CONTENT } from "@src/data/TimelineContent.js"
const ContentOfTheDay = () => {
  const [desktopOnly, setDesktopOnly] = React.useState()

  React.useEffect(() => {
    if (window.screen.width < 700) {
      setDesktopOnly(false)
    } else if (window.screen.width > 700) {
      setDesktopOnly(true)
    }
  }, [desktopOnly])

  function Card({
    title,
    day,
    description,
    readmore,
    github,
    sample,
    demo,
    isMedium,
    _id,
    index,
  }) {
    let numRows = 0
    if (github) numRows += 1
    if (sample) numRows += 1
    if (demo) numRows += 1
    return (
      <>
        {
          <section className={`${styles.card}  numRows-${numRows}`}>
            <Reaction id={_id} day={index + 1} />
            <div className={styles.cardRow}>
              <div className={styles.cardColumn}>
                <div className={styles.cardColumn}>
                  <Text.H3 className={styles.cardDayText}>{day}</Text.H3>
                </div>
                <div className={styles.cardHeader}>
                  <Text.H2 darkBlueGradient>
                    {readmore ? (
                      <a href={readmore} target="_blank" rel="noreferrer">
                        {title}
                      </a>
                    ) : (
                      title
                    )}
                  </Text.H2>
                </div>
                <div className={styles.cardBody}>
                  <Text.P className={styles.cardBodyDescription}>
                    {description}
                  </Text.P>
                </div>
                {
                  <div className={styles.cardBodyButtonGroup}>
                    <a
                      href={readmore}
                      className={styles.cardBodyLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button isPrimary>
                        {isMedium ? "Know More > " : "Step by Step Guide >"}
                      </Button>
                    </a>
                    {github && (
                      <a
                        href={github}
                        className={styles.cardBodyLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button isPrimary className={styles.cardBodyLink}>
                          View Github Repository {">"}
                        </button>
                      </a>
                    )}
                    {sample && (
                      <a
                        href={sample}
                        className={styles.cardBodyLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button isPrimary className={styles.cardBodyLink}>
                          View Sample Project {">"}
                        </button>
                      </a>
                    )}
                    {demo && (
                      <a
                        href={demo}
                        className={styles.cardBodyLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button isPrimary className={styles.cardBodyLink}>
                          Live Demo {">"}
                        </button>
                      </a>
                    )}
                  </div>
                }
              </div>
            </div>
          </section>
        }
      </>
    )
  }
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContainerColumn}>
        {TIMELINE_CONTENT &&
          !desktopOnly &&
          TIMELINE_CONTENT.map(
            (
              {
                _id,
                day,
                title,
                description,
                readmore,
                github,
                sample,
                demo,
                isMedium,
              },
              i
            ) => (
              <Card
                key={i}
                title={title}
                day={day}
                description={description}
                readmore={readmore}
                github={github}
                sample={sample}
                demo={demo}
                isMedium={isMedium}
                _id={_id}
                index={i}
              />
            )
          )}
      </div>
      <div className={styles.cardContainerColumn}>
        {TIMELINE_CONTENT &&
          desktopOnly &&
          TIMELINE_CONTENT.filter((_, i) => i % 2 === 0).map(
            (
              {
                _id,
                day,
                title,
                description,
                readmore,
                github,
                sample,
                demo,
                isMedium,
                index,
              },
              i
            ) => (
              <Card
                key={i}
                title={title}
                day={day}
                description={description}
                readmore={readmore}
                github={github}
                sample={sample}
                demo={demo}
                isMedium={isMedium}
                _id={_id}
                index={index}
              />
            )
          )}
      </div>
      <div className={styles.cardContainerColumn}>
        {TIMELINE_CONTENT &&
          desktopOnly &&
          TIMELINE_CONTENT.filter((_, i) => i % 2 !== 0).map(
            (
              {
                _id,
                day,
                title,
                description,
                readmore,
                github,
                sample,
                demo,
                isMedium,
                index,
              },
              i
            ) => (
              <Card
                key={i}
                title={title}
                day={day}
                description={description}
                readmore={readmore}
                github={github}
                sample={sample}
                demo={demo}
                isMedium={isMedium}
                _id={_id}
                index={index}
              />
            )
          )}
      </div>
    </div>
  )
}

export default ContentOfTheDay
