export const TIMELINE_CONTENT = [
  {
    index: 0,
    _id: "617999488cdfb2002ea8d9d7",
    isMedium: false,
    day: "DAY 1",
    title: "How to build a customer-facing roadmap with React",
    description:
      "A quick start template and guide that will walk you through the steps you need to build a customer-facing roadmap for your SaaS startup using React, email integrations, the upvote feature, etc.",
    readmore:
      "https://dev.to/canonic/how-to-build-a-customer-facing-roadmap-with-react-1gal",
    github: "https://github.com/canonic-dev/canonic-samples/blob/main/roadmap",
    sample: "https://app.canonic.dev/projects/617a311fe42834002d04988a/graph",
    demo: "https://canonic-roadmap.netlify.app/",
  },
  {
    index: 1,
    _id: "61799954c223c6002d191d5c",
    isMedium: true,
    day: "DAY 2",
    title:
      "A SaaS start-up's perspective: Should your product roadmap be public?",
    description:
      "Something we often contemplate about. Understand all the What, Why, and How of making your roadmap public, as well as a step-by-step guide on how to create one.",
    readmore:
      "https://medium.com/@canonicHQ/should-your-roadmap-be-public-1cda23e6daf0",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 2,
    _id: "61799960b1f97f002734891d",
    isMedium: false,
    day: "DAY 3",
    title: "Publish changelogs for your product using React and Tailwind",
    description:
      "Learn how you can create, Release note tools in ReactJS, Tailwind, and GraphQL backend integration and know how to send them to communities as well!",
    readmore:
      "https://dev.to/canonic/publish-changelog-for-your-product-using-react-and-tailwind-nmn",
    github:
      "https://github.com/canonic-dev/canonic-samples/blob/main/release-notes-app",
    sample: "https://app.canonic.dev/projects/6171c828666175002ff70dca/graph",
    demo: "https://canonic-release-notes.netlify.app/",
  },
  {
    index: 3,
    _id: "61799977eaf6d6002713f027",
    day: "DAY 4",
    title: "How to add a notification banner or popup to your website?",
    description:
      "Boost your engagement and highlight the best features with a notification bar. Get your hands on this step-to-step guide to build your own Dynamic Prompts/Notifications bar by using React.",
    readmore:
      "https://dev.to/canonic/how-to-add-a-notification-banner-or-popup-to-your-website-3kmf",
    github:
      "https://github.com/canonic-dev/canonic-samples/blob/main/dynamic-website-popups",
    sample: "https://app.canonic.dev/projects/6176d0597ebc7c002e0bf675/graph",
    demo: "https://dynamic-notifications.netlify.app/",
  },
  {
    index: 4,
    _id: "61799987c223c6002d191d73",
    isMedium: true,
    day: "DAY 5",
    title: "Raise your ROI with lowcode platforms",
    description:
      "Find all the details you need on how lowcode platforms can raise your ROI, with added features and benefits provided by Canonic.",
    readmore:
      "https://medium.com/@canonicHQ/raise-your-roi-with-lowcode-platforms-a71101bac03f",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 5,
    _id: "617999989545db002e5a6bcd",
    isMedium: false,
    day: "DAY 6",
    title: "Build request forms using React, Ant Design, and a lowcode backend",
    description:
      "With this walkthrough, learn how to build Request forms using React, Ant design, and lowcode backend along with sample projects to get yourself a kick start on building contact forms.",
    readmore:
      "https://dev.to/canonic/build-request-forms-using-react-ant-design-and-a-lowcode-backend-26oe",
    github:
      "https://github.com/canonic-dev/canonic-samples/blob/main/canonic-forms",
    sample: "https://app.canonic.dev/projects/617949f799a809002ee528c8/graph",
    demo: "https://canonic-forms.netlify.app/",
  },
  {
    index: 6,
    _id: "617999a4306b840026a3e6f9",
    isMedium: true,
    day: "DAY 7",
    title: "SaaS Startup Toolkit by Canonic",
    description:
      "All you need to have for a SaaS Startup! Build public roadmaps, release logs, dynamic website notifications, and other SaaS tools without a backend.",
    readmore:
      "https://canonic.dev/features/saas-api-pack/?utm_source=canonic_landing_page&utm_medium=organic_canonic&utm_campaign=30daysofcanonic_uc_1&utm_term=card_learn_more&utm_content=day_7",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 7,
    _id: "6179b4448cdfb2002ea8db25",
    isMedium: false,
    day: "DAY 8",
    title:
      "Creating a dashboard for your Postgres database with React and Recharts",
    description:
      "Learn how to build a dashboard on top of PostgresSQL database, Powered by Canonic",
    readmore:
      "https://dev.to/canonic/creating-a-dashboard-for-your-postgres-db-with-react-and-recharts-2ecm",
    github:
      "https://github.com/canonic-dev/canonic-samples/blob/main/postgres-dashboard",
    sample: "https://app.canonic.dev/projects/61ae4ae2b122dd00278f1759/graph",
    demo: "https://canonic-postgres-dashboard.netlify.app/",
  },
  {
    index: 8,
    _id: "6179b493b1f97f0027348a01",
    isMedium: true,
    day: "DAY 9",
    title: "Why data visibility is important for your Startup",
    description:
      "The ability to gain insight in user behaviour and track your goals is crucial at any startup. This is our take on the importance of these goals and how they can be measured easily.",
    readmore:
      "https://medium.com/@canonicHQ/why-data-visibility-is-important-for-your-startup-5a2640fcd034",
  },
  {
    index: 9,
    _id: "6179b49d21311d002711a87d",
    isMedium: false,
    day: "DAY 10",
    title: "A Github PR Dashboard with ReactJS and Material UI",
    description:
      "Track your PR's on Github in the form of a dashboard. A step-by-step guide to building your own custom PR Dashboard in just a matter of minutes.",
    readmore:
      "https://dev.to/canonic/a-github-pr-dashboard-with-reactjs-and-material-ui-ae5",
    github:
      "https://github.com/canonic-dev/canonic-samples/tree/main/github-pr-dashboard",
    sample: "https://app.canonic.dev/projects/61b0eedde08af1002f135b04/graph",
    demo: "https://canonic-github-dashboard.netlify.app/",
  },
  {
    index: 10,
    _id: "6179b4a69545db002e5a6cc1",
    isMedium: true,
    day: "DAY 11",
    title: "Data Aggregation: Low code platforms to the rescue",
    description:
      "Considering a low-code platform for your data aggregation need? Learn everything you need to know about how such platforms allows you to quickly build, and consume using a unified API.",
    readmore:
      "https://medium.com/@canonicHQ/data-aggregation-low-code-platforms-to-the-rescue-24f4ccfca4f5",
  },
  {
    index: 11,
    _id: "6179b4ac21311d002711a894",
    isMedium: false,
    day: "DAY 12",
    title: "How to hydrate data from Stripe and Hubspot",
    description:
      "Hydrate data from these different sources saving you the trouble of manual integration. Walkthrough the guide to learn how to hydrate references from multiple sources.",
    readmore:
      "https://dev.to/canonic/how-to-hydrate-data-from-stripe-and-hubspot-1gf2",
    github:
      "https://github.com/canonic-dev/canonic-samples/tree/main/customer-information",
    sample: "https://app.canonic.dev/projects/61af6165b489750028ed17d2/graph",
    demo: "https://canonic-customer-dashboard.netlify.app/",
  },
  {
    index: 12,
    _id: "6179b4bb8cdfb2002ea8db68",
    isMedium: false,
    day: "DAY 13",
    title: "How to build a marketing dashboard with React and react-chartsjs",
    description:
      "Want to monitor data from multiple social channels? We’ve got you covered. Get all your data in 1 dashboard and customize it as per your needs",
    readmore:
      "https://dev.to/canonic/how-to-build-a-twitter-youtube-analytics-dashboard-with-react-2p2d",
    github:
      "https://github.com/canonic-dev/canonic-samples/tree/main/marketing-dashboard",
    sample: "https://app.canonic.dev/projects/61b4e0b47bfc8f00268a7f9e/graph",
    demo: "https://canonic-marketing-dashboard.netlify.app/",
  },
  {
    index: 13,
    _id: "6179b4c39545db002e5a6cd8",
    isMedium: true,
    day: "DAY 14",
    title: "Connect multiple data sources through a unified API with Canonic",
    description:
      "One-stop solution for dashboards, aggregation platforms, connecting multiple datasources, or just to hydrate references.",
    readmore: "/features/multiple-data-sources/",
  },
  {
    index: 14,
    _id: "6179b4cac223c6002d191eb8",
    inMedium: false,
    day: "DAY 15",
    title: "Youtube to Google Sheets: How to import Youtube public data",
    description:
      "Learn how to access YouTube metrics through Google Sheets reporting, and get weekly updates on Slack as well as an alert.",
    readmore:
      "https://dev.to/canonic/youtube-to-google-sheets-how-to-import-youtube-public-data-1b5h",
    github: "",
    sample: "https://app.canonic.dev/projects/61b78fc80b0203002d823376",
    demo: "",
  },
  {
    index: 15,
    _id: "6179b4d9eaf6d6002713f0aa",
    day: "DAY 16",
    inMedium: true,
    title: "Untangling the web of multiple microservices and integrations",
    description:
      "The solution to the struggle with multiple microservices and integrations, which creates overhead and a problem to contend with.",
    readmore:
      "https://medium.com/@canonicHQ/untangling-the-web-of-multiple-micro-services-and-integrations-701520fe226f",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 16,
    _id: "6179b4e0bfd9d6002629c2b2",
    day: "DAY 17",
    inMedium: false,
    title: "Integrate Github with Asana to track issues in repositories",
    description:
      "Here's how you can build a Github integration, a trigger that is triggered when the count of issues in a repository changes.",
    readmore:
      "https://dev.to/canonic/integrate-github-with-asana-to-track-issues-in-repositories-6p1",
    github: "",
    sample: "https://app.canonic.dev/projects/61ba6a6ab6586d0026b0f9cd/",
    demo: "",
  },
  {
    index: 17,
    _id: "6179b4ef99a809002ee5329c",
    day: "DAY 18",
    inMedium: false,
    title: "Scheduled exports from Shopify to Google sheets",
    description:
      "An in-depth guide with a step-by-step explanation on how to build their scheduled exports from Shopify to Google Sheets. ",
    readmore:
      "https://dev.to/canonic/scheduled-exports-from-shopify-to-google-sheets-495l",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 18,
    _id: "6179b4f88cdfb2002ea8db7f",
    day: "DAY 19",
    isMedium: true,
    title: "Zapier vs. Canonic vs. Integromat: Which connector to use and when",
    description:
      "Wanna know which connector to use and when? A detailed article that outlines various use cases to let you choose the right platform for your specific needs.",
    readmore:
      "https://medium.com/@canonicHQ/zapier-vs-canonic-vs-integromat-which-connector-to-use-and-when-da83e8e18acc",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 19,
    _id: "6179b500b1f97f0027348a2e",
    day: "DAY 20",
    title: "How to create an awesome slack approval workflow",
    isMedium: false,
    description:
      "Trigger interactive workflows on slack, and get the response back with a webhook endpoint to further add a pin in pinterest.",
    readmore:
      "https://dev.to/canonic/how-to-create-an-awesome-slack-approval-workflow-1gdg",
    github: "",
    sample: "https://app.canonic.dev/projects/61be5cc3b6586d0026b16023/graph",
    demo: "",
  },
  {
    index: 20,
    _id: "6179b507c223c6002d191ecf",
    day: "DAY 21",
    isMedium: true,
    title: "Launch: Integration Pack",
    description:
      "Integrations! Integrations Integrations!** Transform data, reduce time spent, scale your integrations, code editor, customized workflows, and whatnot! Find everything you need to integrate.",
    readmore: "https://canonic.dev/features/multiple-integrations",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 21,
    _id: "6179b50ddf897d002dc34abb",
    day: "DAY 22",
    title: "Build Marketplace App with Stripe Billing",
    description:
      "Everything you need to know and have to build a Marketplace app by yourself. With a detailed step-to-step guide to follow and a sample project to give you a kick start.",
    readmore:
      "https://dev.to/canonic/build-marketplace-app-with-stripe-billing-3j8",
    github:
      "https://github.com/canonic-dev/canonic-samples/tree/main/Marketplace-App",
    sample: "https://app.canonic.dev/projects/61bf5c65590bbb002e8a9dc3/graph",
    demo: "https://canonic-marketplace.netlify.app/",
  },
  {
    index: 22,
    _id: "6179b518eaf6d6002713f0c1",
    day: "DAY 23",
    isMedium: true,
    title: "How lowcode is transforming Prototype building",
    description:
      "Know how lowcode tools are better than MVP tools for faster development, while allowing greater extensibility and control through this all-inclusive detailed article.",
    readmore:
      "https://medium.com/@canonicHQ/low-code-revolution-in-mvp-development-c4fa07a51315",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 23,
    _id: "6179b52021311d002711a8ab",
    day: "DAY 24",
    title: "ProductHunt Clone: React + lowcode backend",
    description:
      "Build along with us a version of Producthunt where users can view all the listings and book them.",
    readmore:
      "https://dev.to/canonic/producthunt-clone-react-lowcode-backend-135",
    github:
      "https://github.com/canonic-dev/canonic-samples/tree/main/product-hunt",
    sample: "https://app.canonic.dev/projects/61c677475d064a002d2ef9b8/graph",
    demo: "https://canonic-product-hunt.netlify.app/",
  },
  {
    index: 24,
    _id: "6179b528b1f97f0027348a45",
    day: "DAY 25",
    isMedium: true,
    title: "Constructing Prototypes: Adalo vs Glide vs Canonic",
    description:
      "Know the difference and analysis for Adalo vs Glide vs Canonic, from the point of building MVPs.",
    readmore:
      "https://medium.com/@canonicHQ/constructing-prototypes-adalo-vs-glide-vs-canonic-2d1e9524bcdc",
    github: "",
    sample: "",
    demo: "",
  },
  {
    index: 25,
    _id: "6179b53221311d002711a8c2",
    day: "DAY 26",
    title: "CRM App with React",
    description:
      "A step-by-step guide for building a version of HubSpot that allows you to store details related to customers, like a CRM, send emails, and link to Google Sheets and Asana.",
    readmore: "https://dev.to/canonic/crm-app-with-react-19o3",
    github: "https://github.com/canonic-dev/canonic-samples/tree/main/crm-app",
    sample: "https://app.canonic.dev/projects/61b86184b6586d0026b0b88e/graph",
    demo: "https://canonic-crm.netlify.app/",
  },
  {
    index: 26,
    _id: "6179b53fb1f97f0027348a5c",
    day: "DAY 27",
    title: "Building a restaurant iOS app with Delivery tracking",
    description:
      "Know how to build a simple ordering iOS app in which users can view or buy all dishes/items and track the delivery.",
    readmore: "https://dev.to/canonic/building-a-restaurant-ios-app-4bpm",
    github:
      "https://github.com/canonic-dev/canonic-samples/tree/main/RestaurantApp-iOS",
    sample: "https://app.canonic.dev/projects/61cbfe5f44191100269f5f38",
    demo: "",
  },
  {
    index: 27,
    _id: "6179b547306b840026a3e784",
    day: "DAY 28",
    title: "Launch: Building Prototypes",
    description:
      "Building Prototypes? Know it all here, with detailed guides to follow through, supporting articles for deeper understanding, and sample projects to clone and get started.",
    readmore: "https://canonic.dev/features/mvp",
    github: "",
    sample: "",
    demo: "",
  },
  // {
  //   _id: "6179b564eaf6d6002713f0d8",
  //   day: "DAY 29",
  //   title: "",
  //   description:
  //     "",
  //   readmore: "",
  // github:"",
  // sample:"",
  // demo:"",
  //
  //  },
  // {
  //   _id: "6179b56c21311d002711a8d9",
  //   day: "DAY 30",
  //   title: "",
  //   description:
  //     "",
  //   readmore: "",
  // github:"",
  // sample:"",
  // demo:"",
  //
  //  },
]
