import React from "react"
import DiscordIcon from "@src/icons/discorc.png"

import activateOnScreen from "@src/hoc/activateOnScreen"
import { DISCORD_INVITE } from "@src/const"
import Text from "@src/components/Text"

import * as styles from "./CommunityCallout.module.scss"

const CommunityCallout = activateOnScreen(
  ({ active, innerRef, location, className }) => {
    const activeClassName = active ? styles.active : ""
    return null
    return (
      <section
        ref={innerRef}
        className={`${styles.callouts} ${activeClassName} ${className}`}
      >
        <div className={styles.callout}>
          <article className={styles.calloutBody}>
            <Text.Heading>Community</Text.Heading>
            <Text.H3 whiteTitle>We can be better, together.</Text.H3>
            <Text.Subtitle>
              Reach out to us on Discord for troubleshooting, support or general
              discussions around Canonic.
            </Text.Subtitle>
            <a rel="noreferrer" target="_blank" href={DISCORD_INVITE}>
              <button>Join Discord</button>
            </a>
          </article>
          <figure className={styles.calloutIllustration}>
            <a rel="noreferrer" target="_blank" href={DISCORD_INVITE}>
              <img alt="canonic-request-demo" src={DiscordIcon} />
            </a>
          </figure>
        </div>
      </section>
    )
  }
)

export default CommunityCallout
