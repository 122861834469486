// extracted by mini-css-extract-plugin
export var active = "Section-module--active--dW5N3";
export var black = "Section-module--black--3uCvz";
export var blue = "Section-module--blue--2Awdy";
export var blueGradient = "Section-module--blueGradient--3x04M";
export var center = "Section-module--center--K9lMZ";
export var centerText = "Section-module--centerText--1stdL";
export var flipped = "Section-module--flipped--2cRhk";
export var lightBlue = "Section-module--lightBlue--3ZKjN";
export var link = "Section-module--link--2yBt1";
export var noMargin = "Section-module--noMargin--3lMcl";
export var paddingBottom = "Section-module--paddingBottom--2lDLR";
export var paddingTop = "Section-module--paddingTop--18cvQ";
export var screenshotToSide = "Section-module--screenshotToSide--13Cx6";
export var section = "Section-module--section--qRXGm";
export var sectionButtons = "Section-module--sectionButtons--HZVwW";
export var sectionColumn = "Section-module--sectionColumn--3yXeb";
export var sectionColumnscreenshot = "Section-module--sectionColumnscreenshot--jRSJb";
export var sectionImage = "Section-module--sectionImage--UYr0Y";
export var sectionWrapper = "Section-module--sectionWrapper--1DCOZ";
export var showIn = "Section-module--show-in--1WX_-";
export var smallPaddingBottom = "Section-module--smallPaddingBottom--1-xQs";
export var smallPaddingTop = "Section-module--smallPaddingTop--1TkYq";
export var superscript = "Section-module--superscript--2EHYe";
export var white = "Section-module--white--1dsqW";